import './css/ItemExplain.css';
import { mediaQuery, useMediaQuery } from '../useMediaQuery'
import { JObject } from '../types/interfaces';

type Props = {
  item: JObject | undefined;
};

export const ItemExplain: React.FC<Props> = ({ item }) => {

  const isSp = useMediaQuery(mediaQuery.sp);
  const itemExplain = item?.itemexplain as string;

  const itemExplainCss: React.CSSProperties = {
    width: isSp ? "1.5rem" : "2rem",
    height: isSp ? "1.5rem" : "2rem"
  };

  return (
    itemExplain === null ? null :
    <div className="ItemExplain">
      {itemExplain}
    </div>
  );
};

