import '../css/App.css';
import './css/ItemBackground.css';
import { mediaQuery, useMediaQuery } from '../useMediaQuery'
import { JObject } from '../types/interfaces';

type Props = {
  item: JObject | undefined;
};

export const ItemBackground: React.FC<Props> = ({ item }) => {

  const isSp = useMediaQuery(mediaQuery.sp);
  const itemBackground = item?.itembackground as string;

  const itemExplainCss: React.CSSProperties = {
    width: isSp ? "1.5rem" : "2rem",
    height: isSp ? "1.5rem" : "2rem"
  };

  return (
    itemBackground === null ? null :
      <div className="ItemBackground">
        <h2>作品の生い立ち</h2>
        <div>{itemBackground}</div>
      </div>
  )
};

