import './css/ItemImage.css';
import React, { useState, useEffect, useRef, Dispatch, SetStateAction } from 'react';
import { mediaQuery, useMediaQuery } from '../useMediaQuery'
import { JObject, JArray } from '../types/interfaces';
import { createItemPathByName } from '../utils/helpers';
import { ImageCounter } from '../ImageCounter';
import { FullScreen } from '../FullScreen';
import circleChevtronLeft from "./svg/circle.chevron.left.svg";
import circleChevtronRight from "./svg/circle.chevron.right.svg";

type Props = {
  item: JObject | undefined;
};

export const ItemImage: React.FC<Props> = ({ item }) => {

  const isSp = useMediaQuery(mediaQuery.sp);

  const itemName = item?.itemname as string;
  const itemId = item?.itemid as bigint
  const images = item?.images as JArray ?? [];
  const urls = images.map(p => `https://server.handcraft-jp.com/${createItemPathByName(itemId, p.imageName)}`);
  const fixedImages = images.length === 0 ? [] : [images[images.length - 1], ...images, images[0]];
  const fixedUrls = fixedImages.map(p => `https://server.handcraft-jp.com/${createItemPathByName(itemId, p.imageName)}`);

  const [currentIndex, setCurrentIndex] = useState(1);
  const [isNext, setIsNext] = useState(true);
  const [isAnimating, setIsAnimating] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [isMove, setIsMove] = useState(false);
  const [startX, setStartX] = useState(0);
  const [startTime, setStartTime] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null!);
  const imageRef = useRef<HTMLImageElement[]>(null!);
  const thumbnailRef = useRef<HTMLImageElement[]>(null!);

  imageRef.current = new Array(fixedImages.length).fill(null);
  thumbnailRef.current = new Array(images.length).fill(null);

  const itemImageCss: React.CSSProperties = {
    flexDirection: isSp ? "column" : "row"
  };

  const itemImageThumbnailContainerCss: React.CSSProperties = {
    flexDirection: isSp ? "row" : "column",
    marginTop: isSp ? ".2rem" : 0,
    marginLeft: isSp ? ".25rem" : ".5rem"
  };

  const itemImageChevronCss: React.CSSProperties = {
    display: fixedUrls.length > 3 ? "inline" : "none"
  };

  const itemCounterCss: React.CSSProperties = {
    position: "absolute",
    top: ".5rem",
    right: ".5rem"
  };

  // ボタンイベント
  const handlePrev = () => {
    if (isAnimating) return;
    setIsAnimating(true);
    setIsNext(false);
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? fixedUrls.length - 1 : prevIndex - 1));
  };

  const handleNext = () => {
    if (isAnimating) return;
    setIsAnimating(true);
    setIsNext(true);
    setCurrentIndex((prevIndex) => (prevIndex === fixedUrls.length - 1 ? 0 : prevIndex + 1));
  };

  // タッチイベント
  useEffect(() => {
    fixedUrls.forEach((p, i) => {
      imageRef.current[i]?.addEventListener("touchstart", onTouchStart, { passive: false });
      imageRef.current[i]?.addEventListener("touchmove", onTouchMove, { passive: false });
      imageRef.current[i]?.addEventListener("touchend", onTouchEnd, { passive: false });
    });
    return (() => {
      fixedUrls.forEach((p, i) => {
        imageRef.current[i]?.removeEventListener("touchstart", onTouchStart);
        imageRef.current[i]?.removeEventListener("touchmove", onTouchMove);
        imageRef.current[i]?.removeEventListener("touchend", onTouchEnd);
      });
    });
  }, [imageRef.current]);

  const onTouchStart = (e: TouchEvent) => {
    handleStart(e.touches[0].clientX);
  };

  const onTouchMove = (e: TouchEvent) => {
    const deltaX = e.touches[0].clientX - startX;
    if (Math.abs(deltaX) < 10) return;
    e.preventDefault();
    handleMove(e.touches[0].clientX);
  };

  const onTouchEnd = (e: TouchEvent) => {
    const deltaX = e.changedTouches[0].clientX - startX;
    if (Math.abs(deltaX) < 10) return;
    handleEnd(e.changedTouches[0].clientX);
  };

  // マウスイベント
  const handleMouseDown = (e: React.MouseEvent) => handleStart(e.clientX);
  const handleMouseMove = (e: React.MouseEvent) => handleMove(e.clientX);
  const handleMouseUp = (e: React.MouseEvent) => handleEnd(e.clientX);
  const handleMouseLeave = (e: React.MouseEvent) => handleEnd(e.clientX);

  // 共通イベント
  const handleStart = (clientX: number) => {
    if (isAnimating) return;
    setIsDragging(true);
    setIsMove(false);
    setStartX(clientX);
    setStartTime(Date.now());
  };

  const handleMove = (clientX: number) => {
    if (isAnimating) return;
    if (!isDragging) return;
    setIsMove(true);
    const deltaX = clientX - startX;
    containerRef.current.style.transform = `translateX(calc(-${currentIndex * 100}% + ${deltaX}px))`;
    containerRef.current.style.transition = "none";
  };

  const handleEnd = (clientX: number) => {
    if (isAnimating) return;
    if (!isDragging) return;
    setIsDragging(false);
    // 位置判定
    const deltaX = clientX - startX;
    if (deltaX === 0) return;
    setIsAnimating(true);
    if (deltaX < -containerRef.current.clientWidth / 2) {
      setCurrentIndex(currentIndex + 1);
      setIsNext(true);
      return;
    }
    if (deltaX >= containerRef.current.clientWidth / 2) {
      setCurrentIndex(currentIndex - 1);
      setIsNext(false);
      return;
    }
    // 速度判定
    const endTime = Date.now();
    const duration = endTime - startTime;
    const velocity = Math.abs(deltaX) / duration;
    if (velocity > .3) {
      setCurrentIndex(deltaX < 0 ? currentIndex + 1 : currentIndex - 1);
      setIsNext(deltaX < 0);
      return;
    }
  };

  const handleImageRef = (e: HTMLImageElement | null, index: number) => {
    imageRef.current[index] = e!;
  };

  // クリックイベント
  const handleImageClick = () => {
    if(isMove) return;
    setIsVisible(true);
  };

  const handleClose = () => {
    setIsVisible(false);
  };

  // サムネイルイベント
  const handleThumbnailClick = (index: number) => {
    if (isAnimating) return;
    if (index === calcIndex()) return;
    setIsAnimating(true);
    setCurrentIndex(index + 1);
  };

  const handleThumbnailRef = (e: HTMLImageElement | null, index: number) => {
    thumbnailRef.current[index] = e!;
  };

  // インデックス計算
  const calcIndex = () => {
    let index = currentIndex - 1;
    if (currentIndex === 0) index = fixedUrls.length - 3;
    if (currentIndex === fixedUrls.length - 1) index = 0;
    return index;
  }

  // 移行イベント
  const handleTransitionEnd = () => {
    setIsAnimating(false);
    setIsDragging(false);
    if (isNext && currentIndex === fixedUrls.length - 1) setCurrentIndex(1);
    if (!isNext && currentIndex === 0) setCurrentIndex(fixedUrls.length - 2);
  }

  useEffect(() => {
    const animate = () => {
      // スライド画像
      const duration = isDragging ? .1 : .3;
      containerRef.current.style.transform = `translateX(-${currentIndex * 100}%)`;
      containerRef.current.style.transition = `${isAnimating && (currentIndex > 1 || currentIndex < fixedUrls.length - 1) ? `transform ${duration}s ease-in-out` : "none"}`;
      // サムネイル画像
      let index = calcIndex();
      thumbnailRef.current.forEach((p, i) => { if (p !== null) p.style.filter = i === index ? "none" : "brightness(0.8)" });
      thumbnailRef.current.forEach((p, i) => { if (p !== null) p.style.border = i === index ? "1px solid var(--royalqueen-color)" : "1px solid #ffffff" });
    };
    requestAnimationFrame(animate);
  }, [currentIndex, fixedUrls.length, isAnimating, isDragging, thumbnailRef.current]);

  return (
    <div className="ItemImage" style={itemImageCss}>
      <div className="ItemImage-slider">
        <div className="ItemImage-container" onTransitionEnd={handleTransitionEnd} ref={containerRef}>
          {fixedUrls.map((image, index) => (
            <img key={index} className="ItemImage-item" src={image} alt={`${itemName} ${index - 1}枚目`} onMouseDown={handleMouseDown} onMouseMove={handleMouseMove} onMouseUp={handleMouseUp} onMouseLeave={handleMouseLeave} onClick={handleImageClick} ref={(e) => handleImageRef(e, index)} />
          ))}
        </div>
        <ImageCounter currentIndex={currentIndex} fixedUrls={fixedUrls} style={itemCounterCss} />
        <img className="ItemImage-chevron ItemImage-prev" style={itemImageChevronCss} src={circleChevtronLeft} alt="前の画像" onClick={handlePrev}></img>
        <img className="ItemImage-chevron ItemImage-next" style={itemImageChevronCss} src={circleChevtronRight} alt="次の画像" onClick={handleNext}></img>
      </div>
      <div className="ItemImage-thumbnail-container" style={itemImageThumbnailContainerCss}>
        {urls.map((image, index) =>
          <img key={index} className="ItemImage-thumbnail" src={image} alt={`${itemName} ${index}枚目`} onClick={() => handleThumbnailClick(index)} ref={(e) => handleThumbnailRef(e, index)} />
        )}
      </div>
      <FullScreen isVisible={isVisible} propsCurrentIndex={currentIndex} imageName={itemName} fixedUrls={fixedUrls} onClose={handleClose} />
    </div>
  );
};

