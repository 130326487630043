import '../css/App.css';
import './css/ItemPoint.css';
import { mediaQuery, useMediaQuery } from '../useMediaQuery'
import { JObject } from '../types/interfaces';

type Props = {
  item: JObject | undefined;
};

export const ItemPoint: React.FC<Props> = ({ item }) => {

  const isSp = useMediaQuery(mediaQuery.sp);
  const itemPoint = item?.itempoint as string;

  const itemExplainCss: React.CSSProperties = {
    width: isSp ? "1.5rem" : "2rem",
    height: isSp ? "1.5rem" : "2rem"
  };

  return (
    itemPoint === null ? null :
      <div className="ItemPoint">
        <h2>コツ・ポイント</h2>
        <div>{itemPoint}</div>
      </div>
  )
};

