import '../css/App.css';
import './css/ItemConfig.css';
import { mediaQuery, useMediaQuery } from '../useMediaQuery'
import { JArray, JObject } from '../types/interfaces';

type Props = {
  deliveryMethods: JArray| undefined;
  deliveryDate: JObject | undefined;
};

export const ItemConfig: React.FC<Props> = ({ deliveryMethods, deliveryDate }) => {

  const isSp = useMediaQuery(mediaQuery.sp);
  const deliveryDateName = deliveryDate?.deliverydatename as string

  const itemConfigItemCss: React.CSSProperties = {
    display: isSp ? "block" : "flex",
    gap: isSp ? "none" : "1rem",
    marginTop: isSp ? "1.5rem" : "2rem"
  };

  const itemConfigDeliveryMethodFirstCss: React.CSSProperties = {
    marginTop: isSp ? ".5rem" : 0
  };

  const itemConfigDeliveryMethodOthersCss: React.CSSProperties = {
    marginTop: isSp ? ".25rem": ".75rem"
  };
  
  return (
    <div className="ItemConfig" >
      {deliveryDate === undefined ? null :
        <div className="ItemConfig-item" style={itemConfigItemCss}>
          <h2>発送までの目安</h2>
          <div>{deliveryDateName}</div>
        </div>
      }
      {deliveryMethods === undefined ? null :
        <div className="ItemConfig-item" style={itemConfigItemCss}>
          <h2>配送方法・送料</h2>
          <div>
            {deliveryMethods?.map((p, pIndex) => {
              const deliveryMethodName = p.deliverymethodname as string
              let deliveryPtterns = new JArray(p.deliverypatterns);
              deliveryPtterns = deliveryPtterns.where(q => q.deliveryPatternStatus as number === 2);
              return (deliveryPtterns.map((q, qIndex) => {
                const key = `${pIndex}-${qIndex}`;
                const deliveryPatternName = q.deliveryPatternName as string;
                const deliveryCarriage = q.deliveryCarriage as number;
                const deliveryAddition = q.deliveryAddition as number;
                const itemConfigDeliveryMethodCss = pIndex === 0 && qIndex === 0 ? itemConfigDeliveryMethodFirstCss : itemConfigDeliveryMethodOthersCss
                return (
                  <div key={key} className="ItemConfig-delivery-method" style={itemConfigDeliveryMethodCss}>
                    <div className="ItemConfig-delivery-method-name">{deliveryMethodName}</div>
                    <div className="ItemConfig-delivery-pattern">
                      {deliveryCarriage?.toLocaleString()}円（
                      <a href="#" title="追加送料について" target="_blank">追加送料</a>
                      ：{deliveryAddition?.toLocaleString()}円）<br />
                      {deliveryPatternName}
                    </div>
                  </div>
                )
              }))
            })}
          </div>
        </div>
      }
    </div>
  )
};

