import '../css/Loading.css';
import './css/Contact.css';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';
import { JToken, ErrorEx } from '../types/interfaces';
import { query, elastic, sqlValue, lowercaseKeys, sendMailSeparate } from '../utils/helpers'
import { Content } from "../Content";
import { Loading } from "../Loading";

export const Contact = () => {

  interface Values {
    name: string;
    emailAddress: string;
    subject: string;
    message: string;
  }

  interface Checks {
    confirm: boolean;
  }

  interface Errors {
    name: string;
    emailAddress: string;
    subject: string;
    message: string;
    confirm: string;
  }

  const initValues: Values = { name: "", emailAddress: "", subject: "", message: "" };
  const initChecks: Checks = { confirm: false };
  const initErrors: Errors = { name: "", emailAddress: "", subject: "", message: "", confirm: "" };
  const [values, setValues] = useState<Values>(initValues);
  const [checks, setChecks] = useState<Checks>(initChecks);
  const [errors, setErrors] = useState<Errors>(initErrors);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const subjects = [
    { value: "0", text: "選択してください" },
    { value: "1", text: "会員登録・設定について" },
    { value: "2", text: "ログインについて" },
    { value: "3", text: "作品登録・販売について" },
    { value: "4", text: "購入について" },
    { value: "5", text: "注文のキャンセル依頼" },
    { value: "6", text: "購入者／販売者との連絡について" },
    { value: "7", text: "不具合について" },
    { value: "8", text: "注文完了メール再送依頼" },
    { value: "9", text: "規約違反作品の報告" },
    { value: "10", text: "機能やサービスに対する提案・改善要望" },
    { value: "11", text: "その他" }
  ];

  const handleChange = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement> | React.ChangeEvent<HTMLTextAreaElement>) => {

    if (e as React.ChangeEvent<HTMLInputElement> && e.target.type === "checkbox") {
      const element = e as React.ChangeEvent<HTMLInputElement>;
      const { id, checked } = element.target;
      setChecks((prevValues) => ({ ...prevValues, [id]: checked }));
    }
    else {
      const { id, value } = e.target
      setValues((prevValues) => ({ ...prevValues, [id]: value }));
    }
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // 入力検証
    const errors = validate(values)

    // エラー表示
    setErrors(errors);

    // エラーチェック
    if (Object.values(errors).some(p => p !== "")) return;

    try {
      // ローディング表示
      setIsLoading(true);

      // お問い合わせ挿入
      const result = await insertQuery();
      await insertElastic(result);

      // 件名
      const subject = subjects.find(p => p.value === values.subject)?.text;

      // 文面
      let text = "";
      text += `差出人: ${values.name} ${values.emailAddress}\n`;
      text += `題名: ${subject}\n`;
      text += `\n`;
      text += `メッセージ本文:\n`;
      text += `${values.message}\n`;
      text += `\n`;
      text += `--\n`;
      text += `このメールは ハンドクラフト (https://handcraft-jp.com) のお問い合わせフォームから送信されました`;

      // メール作成
      const data = {
        from: "ハンドクラフト<support@handcraft-jp.com>",
        to: "ハンドクラフト<support@handcraft-jp.com>",
        cc: `${values.name}<${values.emailAddress}>`,
        replyTo: `${values.name}<${values.emailAddress}>`,
        subject: subject,
        text: text
      }

      // メール送信
      await sendMailSeparate(data);

      // ローディング非表示
      setIsLoading(false);

      // ナビゲート      
      navigate("/contact/thanks");
    }
    catch (error) {

      // ローディング非表示
      setIsLoading(false);

      if (error instanceof AxiosError) {
        console.log('AxiosError:', error);
        return;
      }

      if (error instanceof ErrorEx) {
        console.log('ErrorEx:', error.errors);
        return;
      }
      
      if (error instanceof Error) {
        console.log('ErrorEx:', error);
        return;
      }

      console.log('error:', error);
    }
  }

  const validate = (values: Values) => {
    const errors: Errors = { name: "", emailAddress: "", subject: "", message: "", confirm: "" };
    const regex = new RegExp(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/);

    // お名前
    if (!values.name) { errors.name = "お名前を入力してください。"; }

    // 回答先メールアドレス
    if (!values.emailAddress) { errors.emailAddress = "回答先メールアドレスを入力してください。"; }
    else if (!regex.test(values.emailAddress)) { errors.emailAddress = "正しい回答先メールアドレスを入力してください。"; }

    // ご用件
    if (!values.subject || values.subject === "0") { errors.subject = "ご用件を選択してください。"; }

    // お問い合わせ
    if (!values.message) { errors.message = "お問い合わせを入力してください。"; }

    // 入力確認
    if (!checks.confirm) { errors.confirm = "入力内容を確認してください。"; }

    return errors;
  }

  const insertQuery = async (): Promise<JToken[][]> => {

    const name = sqlValue(values.name);
    const emailAddress = sqlValue(values.emailAddress);
    const subject = sqlValue(values.subject);
    const message = sqlValue(values.message);

    let sql = ""
    sql += `INSERT INTO d900_contact (userId, contactName, contactEmailAddress, contactSubject, contactMessage, contactEntry) `;
    sql += `VALUES (NULL, ${name}, ${emailAddress}, ${subject}, ${message}, NOW(3));`;
    sql += `SELECT * FROM d900_contact WHERE contactId = LAST_INSERT_ID();`;

    return await query(sql);
  }

  const insertElastic = async (results: JToken[][]) => {

    const contact = results[0][0];

    const j = lowercaseKeys(contact);
    j.contactentry = new Date(j.contactentry).toISOString();

    const contactId = j.contactid as number;

    await elastic("d900_contact", `_create/${contactId}?refresh`, j);
  }

  return (
    <div>
      <Content
        children={
          <div className="Contact">
            <form onSubmit={(e) => handleSubmit(e)}>
              <h1>お問い合わせ</h1>
              <p>
                お問い合わせの際の注意事項
              </p>
              <ul>
                <li>原則2営業日以内にご連絡を差し上げますが、お問い合わせが混み合っている、または、調査等が必要なお問い合わせは、返信までにお時間をいただく場合がございます。</li>
                <li>迷惑メール設定等により、「ハンドクラフト」からの返信を受信できない場合がございます。「@handcraft-jp.com」の受信設定の許可をお願いします。</li>
              </ul>
              <p>
                お問い合わせフォーム
              </p>
              <p>
                <label>お名前<br />
                  <input type="text" id="name" onChange={(e) => handleChange(e)} />
                  <span>{errors.name}</span>
                </label>
              </p>
              <p>
                <label>回答先メールアドレス<br />
                  <input type="text" id="emailAddress" onChange={(e) => handleChange(e)} />
                  <span>{errors.emailAddress}</span>
                </label>
              </p>
              <p>
                <label>ご用件<br />
                  <select id="subject" onChange={(e) => handleChange(e)}>
                    {subjects.map(p => (
                      <option key={p.value} value={p.value}>
                        {p.text}
                      </option>
                    ))}
                  </select>
                  <span>{errors.subject}</span>
                </label>
              </p>
              <p>
                <label>お問い合わせ<br />
                  <textarea cols={40} rows={10} id="message" onChange={(e) => handleChange(e)}></textarea>
                  <span>{errors.message}</span>
                </label>
              </p>
              <p>
                <label>
                  <input type="checkbox" id="confirm" onChange={(e) => handleChange(e)} />
                  入力内容を確認しました
                  <span>{errors.confirm}</span>
                </label>
              </p>
              <p className="Contact-submit">
                <input type="submit" value="お問い合わせを送信" />
              </p>
            </form>
          </div>
        }
        isNarrow={true}
      />
      <Loading isLoading={isLoading} />
    </div>
  );
};
