import './css/Item.css';
import '../css/Blocks.css';
import { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import { JObject, JArray } from '../types/interfaces';
import { selectElasticItem, selectElasticUser, selectElasticDeliveryMethodsByDeliverySetId, selectElasticDeliveryDate, selectElasticFavorite } from '../utils/helpers';
import { mediaQuery, useMediaQuery } from '../useMediaQuery'
import { Content } from "../Content";
import { ItemImage } from './ItemImage';
import { ItemAbstract } from './ItemAbstract';
import { ItemExplain } from './ItemExplain';
import { ItemMaterial } from './ItemMaterial';
import { ItemMake } from './ItemMake';
import { ItemPoint } from './ItemPoint';
import { ItemBackground } from './ItemBackground';
import { ItemConfig } from './ItemConfig';
import appStore from "./svg/Download_on_the_App_Store_Badge_JP_RGB_blk_100317.svg"

export const Item = () => {

  const isSp = useMediaQuery(mediaQuery.sp);
  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const itemId = BigInt(query.get('itemid') ?? "0");
  const [item, setItem] = useState<JObject>();
  const [user, setUser] = useState<JObject>();
  const [deliveryMethods, setDeliveryMethods] = useState<JArray>();
  const [deliveryDate, setDeliveryDate] = useState<JObject>();
  const [favoriteTotal, setFavoriteTotal] = useState<number>(0);

  const itemCss: React.CSSProperties = {
    paddingBottom: isSp ? "16px" : 0
  };

  const itemMainCss: React.CSSProperties = {
    display: isSp ? "block" : "grid"
  };

  const itemRightCss: React.CSSProperties = {
    padding: isSp ? "0 16px 0" : 0
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const itemRes = await selectElasticItem(itemId);
        const item = itemRes.source();
        const userId = item?.userid as bigint;
        const deliverySetId = item?.deliverysetid as bigint;
        const deliveryDateId = item?.deliverydateid as bigint;
        const userAsync = selectElasticUser(userId);
        const deliveryMethodsAsync = selectElasticDeliveryMethodsByDeliverySetId(deliverySetId);
        const deliveryDateAsync = selectElasticDeliveryDate(deliveryDateId);
        const favoriteAsync = selectElasticFavorite(undefined, itemId, 0);

        const [userRes, deliveryMethodsRes, deliveryDateRes, favoriteRes] = await Promise.all([userAsync, deliveryMethodsAsync, deliveryDateAsync, favoriteAsync]);

        const user = userRes.source();
        const deliveryMethods = deliveryMethodsRes?.sources();
        const deliveryDate = deliveryDateRes?.source();
        const favoriteTotal = favoriteRes.total();

        setItem(item);
        setUser(user);
        setDeliveryMethods(deliveryMethods);
        setDeliveryDate(deliveryDate);
        setFavoriteTotal(favoriteTotal);
      }
      catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [itemId]);

  return (
    <Content padding={false}
      children={
        <div className="Item" style={itemCss}>
          <div className="Item-main" style={itemMainCss}>
            <div className="Item-left">
              <ItemImage item={item} />
            </div>
            <div className="Item-right" style={itemRightCss}>
              <ItemAbstract item={item} user={user} favoriteTotal={favoriteTotal} />
              <ItemExplain item={item} />
              <ItemMaterial item={item} />
              <ItemMake item={item} />
              <ItemPoint item={item} />
              <ItemBackground item={item} />
              <ItemConfig deliveryMethods={deliveryMethods} deliveryDate={deliveryDate} />
            </div>
          </div>

          <div className="Home-download">
            <p>
              <span>いつでもどこでもお手軽に。</span>
              <br />ハンドクラフトのアプリを無料ダウンロード
            </p>
            <a target="_blank" rel="noreferrer" href={process.env.REACT_APP_APPSTORE}>
              <img src={appStore} alt="App Store アイコン" />
            </a>
          </div>
        </div>
      }
    />
  );
};

