import "./css/ImageCounter.css";
import { mediaQuery, useMediaQuery } from './useMediaQuery'

type Props = {
  currentIndex: number;
  fixedUrls: string[];
  style: React.CSSProperties;
};

export const ImageCounter: React.FC<Props> = ({ currentIndex, fixedUrls, style }) => {

  const isSp = useMediaQuery(mediaQuery.sp);

  const imageCounter: React.CSSProperties = {
    fontSize: isSp ? ".75rem" : ".875rem",
    width: isSp ? "3rem" : "4rem",
    height: isSp ? "1.5rem" : "2rem",
    borderRadius: isSp ? ".75rem" : "1rem" 
  };

  const calcIndex = () => {
    let index = currentIndex - 1;
    if (currentIndex === 0) index = fixedUrls.length - 3;
    if (currentIndex === fixedUrls.length - 1) index = 0;
    return index;
  }

  return (
    <div className="ItemCounter" style={{ ...style, ...imageCounter }}>{calcIndex() + 1} / {fixedUrls.length - 2}</div>
  );
};
