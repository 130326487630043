import '../css/Common.css';
import { Content } from "../Content";
import { FetchHtml } from '../FetchHtml';

export const Termsps = () => {

  return (
    <div>
      <Content
        children={
          <div>
            <h1>プレミアムサービス利用規約</h1>
            <FetchHtml url="./termsps/termsps.html" />
          </div>
        }
        isNarrow={true}
      />
    </div>
  );
};
