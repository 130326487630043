import '../css/Blocks.css';
import { BlocksItem } from "../BlocksItem";
import { mediaQuery, useMediaQuery } from '../useMediaQuery'
import cartFill from "./svg/cart.fill.svg"
import giftFill from "./svg/gift.fill.svg"
import nosign from "./svg/nosign.svg"

export const FaqList = () => {
  const isSp = useMediaQuery(mediaQuery.sp);

  const blocksList = {
    margin: isSp ? "0" : "0 -15px",
    padding: isSp ? "0" : "15px 0"
  };

  return (
    <section>

      {/* <section>
        <h3>はじめるガイド</h3>
        <ul className="BlocksList">
          <FaqBlocksItem linkUrl="#" imageUrl="#" title="ハンドクラフトで販売をはじめる" description="作品を販売する際の手順や便利機能のご紹介!" />
          <FaqBlocksItem linkUrl="#" imageUrl="#" title="作品販売のノウハウ集" description="作品の撮影や広く知ってもらうコツなど" />
          <FaqBlocksItem linkUrl="#" imageUrl="#" title="安心・安全にお使いいただくために" description="安心してお使いいただくための取り組み" />
        </ul>
      </section> */}

      <section>
        {/* <h3>ヘルプ・マニュアル</h3> */}
        <ul className="BlocksList" style={blocksList}>
          {/* <FaqBlocksItem linkUrl="#" imageUrl="#" title="会員・販売者登録" description="新規会員登録・販売者登録について" /> */}
          {/* <FaqBlocksItem linkUrl="#" imageUrl="#" title="ログイン" description="ログインできない・パスワード忘れなど" /> */}
          {/* <FaqBlocksItem linkUrl="#" imageUrl="#" title="設定" description="会員情報・販売者情報・メール受信設定など" /> */}
          <BlocksItem linkUrl="/help/purchase" imageUrl={cartFill} title="購入" description="お支払い・レビューなど" />
          <BlocksItem linkUrl="/help/sales" imageUrl={giftFill} title="販売" description="作品の販売・登録・送料など" />
          {/* <FaqBlocksItem linkUrl="#" imageUrl="#" title="受注したあと" description="代金・発送・売上管理など" /> */}
          {/* <FaqBlocksItem linkUrl="#" imageUrl="#" title="クーポン・ポイント・ギフトチケット" description="お買い物に使えるシステムについて" /> */}
          <BlocksItem linkUrl="/help/cancel" imageUrl={nosign} title="注文のキャンセル" description="注文のキャンセル・キャンセル対応について" reverse={true} />
          {/* <FaqBlocksItem linkUrl="#" imageUrl="#" title="ハンドクラフト広告" description="ハンドクラフト広告の使い方・仕様など" /> */}
          {/* <FaqBlocksItem linkUrl="#" imageUrl="#" title="サービスについて" description="推奨環境・機能など" /> */}
          </ul>
      </section>

    </section>
  );
};
