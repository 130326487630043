import '../css/Common.css';
import { useLocation } from "react-router-dom";
import { FaqList } from "./FaqList";
import { FaqItem } from "./FaqItem";

export const Faq = () => {
  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const item = query.get('item');
  console.log(item);

  return (
    <div className="Common-content">
      <div className="Common-wrap">
        <div className="Common-main">
          <h1>ヘルプとガイド</h1>
          {item === null ? <FaqList /> : <FaqItem />}
        </div>
      </div>
    </div>
  );
};
