import './css/ItemAbstract.css';
import { useContext, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { mediaQuery, useMediaQuery } from '../useMediaQuery'
import { GlobalContextType, JObject } from '../types/interfaces';
import { createUserPath } from '../utils/helpers';
import { ItemOption } from './ItemOption';
import { Message } from '../Message';
import mdLink from "./svg/md.link.svg";
import ioHeartCircle from "./svg/io.heart.circle.svg";
import { GlobalContext } from '../App';
import { FullScreen } from '../FullScreen';

type Props = {
  item: JObject | undefined;
  user: JObject | undefined;
  favoriteTotal: number | undefined;
};

export const ItemAbstract: React.FC<Props> = ({ item, user, favoriteTotal }) => {

  const isSp = useMediaQuery(mediaQuery.sp);
  const itemName = item?.itemname as string;
  const itemPrice = item?.itemprice as number | null;
  const itemInventory = item?.iteminventory as number | null;
  const itemUnit = item?.itemunit as string;

  const userId = user?.userid as bigint
  const userName = user?.username as string
  const userUrl = `https://server.handcraft-jp.com/${createUserPath(userId)}`;
  const fixedUrls = new Array(3).fill(userUrl);

  const globalContext = useContext<GlobalContextType>(GlobalContext);
  const [isShareMenuVisible, setIsShareMenuVisible] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [message, setMessage] = useState("");
  const [link, setLink] = useState<string>();
  const [href, setHref] = useState<string>();
  const [uuid, setUuid] = useState("");

  const itemAbstractIcon: React.CSSProperties = {
    width: isSp ? "1.5rem" : "2rem",
    height: isSp ? "1.5rem" : "2rem"
  };

  const itemAbstractShareMenu: React.CSSProperties = {
    display: isShareMenuVisible ? "block" : "none",
    left: isSp ? 0 : "50%",
    transform: isSp ? "none" : "translateX(-50%)"
  };

  const itemAbstractItemName: React.CSSProperties = {
    fontSize: isSp ? "1rem" : "1.25rem"
  };

  const itemAbstractItemPrice: React.CSSProperties = {
    fontSize: isSp ? "1.25rem" : "1.5rem"
  };

  const itemAbstractItemCurrency: React.CSSProperties = {
    fontSize: isSp ? ".75rem" : ".875rem"
  };

  const itemAbstractItemInventory: React.CSSProperties = {
    fontSize: isSp ? ".75rem" : ".875rem"
  };

  useEffect(() => {
    setIsShareMenuVisible(false);
  }, [globalContext.clickUuid]);

  const handleShareButtonClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    setIsShareMenuVisible(!isShareMenuVisible);
  };

  const handleIconClick = () => {
    setIsVisible(true);
  };

  const handleClose = () => {
    setIsVisible(false);
  };

  const handleShareLinkButtonClick = async () => {
    await navigator.clipboard.writeText(window.location.href);
    setMessage("クリップボードにコピーしました");
    setLink(undefined);
    setHref(undefined);
    setUuid(uuidv4());
  };

  const handleFavoriteIconClick = () => {
    setMessage("アプリにてお気に入り登録できます");
    setLink("ダウンロード");
    setHref(process.env.REACT_APP_APPSTORE);
    setUuid(uuidv4());
  };

  const handleCartButtonClick = () => {
    setMessage("アプリにてカートを利用できます");
    setLink("ダウンロード");
    setHref(process.env.REACT_APP_APPSTORE);
    setUuid(uuidv4());
  };

  return (
    <div className="ItemAbstract" >
      <div className="ItemAbstract-button-list">
        <button className="ItemAbstract-share-button" onClick={(e) => handleShareButtonClick(e)}>
          <i className="ItemAbstract-share-icon fa-solid fa-arrow-up-from-bracket"></i>
          共有
          <div className="ItemAbstract-share-menu" style={itemAbstractShareMenu}>
            <ul>
              <li>
                <button onClick={() => handleShareLinkButtonClick()}>
                  <img className="ItemAbstract-share-menu-link" src={mdLink} alt="URLをコピー" />
                  URLをコピー
                </button>
              </li>
            </ul>
          </div>
        </button>
      </div>
      <div className="ItemAbstract-header">
        <div className="ItemAbstract-header-left">
          <div className="ItemAbstract-user">
            <img className="ItemAbstract-icon" style={itemAbstractIcon} src={userUrl} alt="ユーザーアイコン" onClick={() => handleIconClick()}></img>
            <div className="ItemAbstract-username">{userName}</div>
          </div>
          <h1 className="ItemAbstract-itemname" style={itemAbstractItemName}>{itemName}</h1>
          {itemPrice === null ? null :
            <div>
              <div className="ItemAbstract-itemprice" style={itemAbstractItemPrice}>
                {itemPrice?.toLocaleString()}
                <span className="ItemAbstract-itemcurrency" style={itemAbstractItemCurrency}>円</span>
              </div>
              <div className="ItemAbstract-iteminventory" style={itemAbstractItemInventory}>残り{itemInventory === null ? null : itemInventory?.toLocaleString()}{itemUnit}</div>
            </div>
          }
        </div>
        <div className="ItemAbstract-header-right">
          <img className="ItemAbstract-favorite" src={ioHeartCircle} alt="お気に入りアイコン" onClick={() => handleFavoriteIconClick()} />
          <div className="ItemAbstract-favorite-count">{favoriteTotal}</div>
        </div>
      </div>
      <ItemOption item={item} />
      <button className="ItemAbstract-cart" onClick={() => handleCartButtonClick()}>カートに入れる</button>
      <FullScreen isVisible={isVisible} propsCurrentIndex={1} imageName={userName} fixedUrls={fixedUrls} onClose={handleClose} isCounter={false} />
      <Message message={message} link={link} href={href} uuid={uuid} />
    </div>
  );
};

