import '../css/App.css';
import './css/ItemOption.css';
import { mediaQuery, useMediaQuery } from '../useMediaQuery'
import { JArray, JObject } from '../types/interfaces';

type Props = {
  item: JObject | undefined;
};

export const ItemOption: React.FC<Props> = ({ item }) => {

  const isSp = useMediaQuery(mediaQuery.sp);
  const options = new JArray(item?.options);
  const g = options.where(p => p.optionStatus === 2).ascends(p => p.optionId, q => q.optionIndex).groupBy(p => p.optionId);

  const itemExplainCss: React.CSSProperties = {
    width: isSp ? "1.5rem" : "2rem",
    height: isSp ? "1.5rem" : "2rem"
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement> | React.ChangeEvent<HTMLTextAreaElement>) => {

    // if (e as React.ChangeEvent<HTMLInputElement> && e.target.type === "checkbox") {
    //   const element = e as React.ChangeEvent<HTMLInputElement>;
    //   const { id, checked } = element.target;
    //   setChecks((prevValues) => ({ ...prevValues, [id]: checked }));
    // }
    // else {
    //   const { id, value } = e.target
    //   setValues((prevValues) => ({ ...prevValues, [id]: value }));
    // }
  }

  return (
    options.length === 0 ? null :
      <div className="ItemOption">
        {g.map((p, i) => {
          const optionTitle = p[1].where(q => q.optionIndex === 0).firstOrDefault();
          const optionName = optionTitle?.optionName;
          const optionRequired = optionTitle?.optionRequired as number;
          const optionItems = p[1].where(q => q.optionIndex > 0)
          return (
            <div key={i} className="ItemOption-item">
              <div className="ItemOption-name">
                {optionName}
                {optionRequired === 1 && <div className="ItemOption-required">必須</div>}
              </div>
              <div className="ItemOption-option">
                <select onChange={(e) => handleChange(e)}>
                  <option key={-1} value={-1}>選択しない</option>
                  {optionItems.map(q => (
                    <option key={q.optionUniqueId} value={q.optionIndex}>
                      {q.optionName}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          )
        }
        )}
      </div>
  )
};

