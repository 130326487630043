import React from 'react';
import './css/FaqHeader.css';
import { useNavigate } from "react-router-dom";

type Props = {
    title: string;
}

export const FaqHeader: React.FC<Props> = ({title}) => {
  const navigate = useNavigate();

  return (
    <div className="FaqHeader">
      <h1>{title}</h1>
      <a className="Back" onClick={() => navigate(-1)}>戻る</a>
    </div>
  );
};
