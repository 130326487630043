import { JToken, ErrorEx, JObject } from '../types/interfaces'
import axios, { AxiosError } from "axios";
import md5 from 'md5'
import { v4 as uuidv4 } from 'uuid';

//
// 配列
//
export const deepCopy = <T>(obj: T): T => {

  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }

  const copy: any = Array.isArray(obj) ? [] : {};
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      copy[key] = deepCopy(obj[key]);
    }
  }

  return copy as T;
}

//
// Sql Server
//
export const query = async (sql: string, transaction: boolean = false, cmd: string[] = [], roll: string[] = []): Promise<JToken[][]> => {

  const data: JToken = { sql: sql, tran: transaction, cmd: cmd, roll: roll };

  const url = "https://server.handcraft-jp.com/query.php";

  const username = process.env.REACT_APP_USER_ID as string;
  const password = process.env.REACT_APP_PASSWORD as string;

  const ret = await request("POST", url, username, password, "", data);

  const status = ret.status as boolean;
  const error = ret.error as string[];
  const result = ret.result as JToken[][];

  if (!status) throw new ErrorEx(error);

  return result;
}

export const sqlValue = <T>(t?: T): string => {

  // NULL値や空文字列の処理
  if (t === undefined || (typeof t === "string" && t.length === 0)) { return "NULL"; }

  return sqlValueInternal(t as any);
}

const sqlValueInternal = <T>(t: T): string => {

  // 型ごとの処理
  if (typeof t === "string") return `'${t.replace(/['"\\]/g, '\\$&')}'`;
  if (typeof t === "number") return `${t}`;
  if (t instanceof Date) return `'${t.toISOString()}'`;

  return `${t}`;
}

//
// Elastic Search
//
export const selectElasticUser = async (userId: bigint): Promise<JObject> => {

  return await elastic("m001_user", "_search", { query: { term: { userid: userId.toString() } } });
}

export const selectElasticItem = async (itemId: bigint): Promise<JObject> => {

  return await elastic("m020_item", "_search", { query: { term: { itemid: itemId.toString() } } });
}

export const selectElasticDeliveryMethodsByDeliverySetId = async (deliverySetId: bigint): Promise<JObject | undefined> => {

  if (deliverySetId === null) return Promise.resolve(undefined);
  return await elastic("d071_delivery_method", "_search", { query: { term: { deliverysetid: deliverySetId.toString() } } });
}

export const selectElasticDeliveryDate = async (deliveryDateId: bigint): Promise<JObject | undefined> => {

  if (deliveryDateId === null) return Promise.resolve(undefined);
  return await elastic("d073_delivery_date", "_search", { query: { term: { deliverydateid: deliveryDateId.toString() } } });
}

//
// お気に入り
//
export const selectElasticFavorite = async (userId: bigint | undefined, itemId: bigint, size: number = 10): Promise<JObject> => {
  return selectElasticFavoriteBase(userId, [itemId], size);
}

export const selectElasticFavoriteBase = async (userId: bigint | undefined, itemIds: bigint[], size: number = 10): Promise<JObject> => {

  const dicMust: any[] = [];
  if (userId !== undefined) dicMust.push({ term: { userid: userId.toString() } });
  dicMust.push({ terms: { itemid: itemIds.map(p => p.toString()) } });
  dicMust.push({ term: { favoritestatus: 1 } });
  const dicBool = { must: dicMust };
  const dicQuery = { bool: dicBool };

  const dic = {
    query: dicQuery,
    size: size,
  };

  return await elastic("d080_favorite", "_search", dic);
}

export const elastic = async (index: string, uri: string, data?: JToken): Promise<JObject> => {

  const url = `https://server.handcraft-jp.com:9400/${index}/${uri}`;

  const username = process.env.REACT_APP_USER_ID as string;
  const password = process.env.REACT_APP_PASSWORD as string;

  const result = await request("POST", url, username, password, "application/json", data);

  if (result.error) { throw new Error(String(result.error)); }
  if (result.errors) { throw new Error(String(result.items)); }

  return new JObject(result);
}

export const lowercaseKeys = (obj: JToken): JToken => {

  return Object.keys(obj).reduce((acc, key) => {
    acc[key.toLowerCase()] = obj[key];
    return acc;
  }, {} as JToken);
}

//
// Mail
//
export const sendMailSeparate = async (data: JToken) => {

  const promiseTo = sendMailTo(data, data.to);
  const promiseCc = sendMailTo(data, data.cc, "(控え) ");
  const promiseBcc = sendMailTo(data, data.bcc, "(控え) ");

  const promises: Promise<void>[] = [];
  if (data.to) promises.push(promiseTo);
  if (data.cc) promises.push(promiseCc);
  if (data.bcc) promises.push(promiseBcc);

  await Promise.all(promises);
}

const sendMailTo = async (data: JToken, to: string, prefix: String = "") => {

  let dataTo = { ...data };
  dataTo.subject = `${prefix}${data.subject}`;
  dataTo.to = to;
  dataTo.cc = null;
  dataTo.bcc = null;

  await sendMail(dataTo);
}

export const sendMail = async (data: JToken) => {

  const url = "https://server.handcraft-jp.com:5000";

  const username = process.env.REACT_APP_USER_ID as string;
  const password = process.env.REACT_APP_PASSWORD as string;

  let result = await request("POST", url, username, password, "", data);
}

//
// Digest認証リクエスト
//
const request = async (method: string, url: string, username: string, password: string, contentType: string, data?: JToken): Promise<JToken> => {

  const parse = (string: string, field: string, trim: boolean = true) => {
    const regex = new RegExp(`${field}=("[^"]*"|[^,]*)`, "i");
    const match = regex.exec(string);
    if (match) return trim ? match[1].replace(/(^[\s"]|[\s"]$)/g, '') : match[1];
    return "";
  }

  const createAuthorization = (method: string, uri: string, username: string, password: string, realm: string, qop: string, nonce: string, nc: number, cnonce: string): string => {

    // nc文字列作成
    const ncString = nc.toString().padStart(8, '0');

    // response計算
    const A1 = md5(`${username}:${realm}:${password}`);
    const A2 = md5(`${method}:${uri}`);
    const response = md5(`${A1}:${nonce}:${ncString}:${cnonce}:${qop}:${A2}`);

    // Authorization作成
    return `Digest username="${username}",realm="${realm}",nonce="${nonce}",uri="${uri}",qop=${qop},nc=${ncString},cnonce="${cnonce}",response="${response}"`
  }

  try {

    // 初回リクエストで401 Unauthorizedが返ってきた場合、レスポンスヘッダーから必要な情報を取得
    const response = await axios({ method: method, url: url });

    return response.data;
  }
  catch (error) {

    // Digest認証
    if (error instanceof AxiosError) {

      if (error.response && error.response.status === 401) {

        const urlObj = new URL(url);
        let uri = `${urlObj.pathname}${urlObj.search}`;

        const headers = error.response.headers;
        const key = Object.keys(headers).find(key => key.match(/^www-authenticate$/i)) || "";
        const header = headers[key];
        const nonce = parse(header, "nonce");
        const realm = parse(header, "realm");
        const qop = parse(header, "qop");

        const nc = 1;
        const cnonce = uuidv4().replace(/-/g, '');

        // Digest認証ヘッダ作成
        const authorization = createAuthorization(method, uri, username, password, realm, qop, nonce, nc, cnonce);

        try {

          const response = await axios({
            method: method,
            headers: {
              "Authorization": authorization,
              //"Content-Type": contentType
            },
            url: url,
            data: data
          });

          return response.data;
        }
        catch (error) { throw error }
      }
    }

    throw error;
  }
}

//
// 画像パス
//
export function createUserPath(userId: bigint | undefined): string {
  return createPath("user", userId);
}

export const createUserPathByName = (userId: bigint | undefined, filename: string = ""): string => {
  return createPathByName("user", userId, filename);
}

export const createItemPath = (itemId: bigint | undefined): string => {
  return createPath("item", itemId);
}

export const createItemPathByName = (itemId: bigint | undefined, filename: string = ""): string => {
  return createPathByName("item", itemId, filename);
}

export const createItemDirectory = (itemId: bigint | undefined): string => {
  return createDirectory("item", itemId);
}

export const createReportPath = (reportId: bigint | undefined): string => {
  return createPath("report", reportId);
}

export const createReportPathByName = (reportId: bigint | undefined, filename: string = ""): string => {
  return createPathByName("report", reportId, filename);
}

const createPath = (folder: string, id: bigint | undefined): string => {
  return createDirectory(folder, id) + createFilename(id);
}

const createPathByName = (folder: string, id: bigint | undefined, filename: string): string => {
  return createDirectory(folder, id) + createFilenameByName(filename);
}

const createDirectory = (folder: string, id: bigint | undefined): string => {
  if (id === undefined) return "";
  const formattedId = id.toString().padStart(20, "0");
  const segments = [
    formattedId.substring(0, 4),
    formattedId.substring(4, 8),
    formattedId.substring(8, 12),
    formattedId.substring(12, 16),
    formattedId.substring(16, 20),
  ];

  return `${folder}/${segments.join("/")}/`;
}

const createFilename = (id: bigint | undefined): string => {
  if (id === undefined) return "";
  return id.toString().padStart(20, "0") + ".jpg";
}

const createFilenameByName = (filename: string): string => {
  return filename + ".jpg";
}
