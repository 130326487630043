import './css/Footer.css';
import { Link } from "react-router-dom";
import { FooterMenu } from "./FooterMenu";
import { mediaQuery, useMediaQuery } from './useMediaQuery'

export const Footer = () => {
	const isPc = useMediaQuery(mediaQuery.pc);

	const footerLogo: React.CSSProperties = {
		position: isPc ? "absolute" : "relative",
		bottom: isPc ? "0" : "auto"
	};

	const footerCopyright: React.CSSProperties = {
		textAlign: isPc ? "right" : "center"
	}

	return (
		<div>
			<footer className="Footer">
				<div className="Common-wrap">
					<div className="Footer-bottom">
						<div className="Footer-logo" style={footerLogo}>
							<Link to="/" className="Footer-site-name">ハンドクラフト</Link>
						</div>
						<div className="Footer-copyright" style={footerCopyright}>© 2024 ハンドクラフト.</div>
					</div>
				</div>
			</footer>
			<FooterMenu />
		</div>
	);
};
