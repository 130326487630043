import './css/Home.css';
import '../css/Blocks.css';
import { Content } from "../Content";
import { BlocksItem } from "../BlocksItem";
import { mediaQuery, useMediaQuery } from '../useMediaQuery'
import listBulletRectanglePortraitFill from "./svg/list.bullet.rectangle.portrait.fill.svg"
import bookClosedFill from "./svg/book.closed.fill.svg"
import crownFill from "./svg/crown.fill.svg"
import questionmarkCircleFill from "./svg/questionmark.circle.fill.svg"
import textBubbleFill from "./svg/text.bubble.fill.svg"
import firewallFill from "./svg/firewall.fill.svg"
import appStore from "./svg/Download_on_the_App_Store_Badge_JP_RGB_blk_100317.svg"

export const Home = () => {
	const isSp = useMediaQuery(mediaQuery.sp);

	const blocksList = {
		margin: isSp ? "0" : "0 -15px",
		padding: isSp ? "0" : "15px 0"
	};

	return (
		<Content
			children={

				<section>

					<h1>ホーム</h1>

					<section>
						<ul className="BlocksList" style={blocksList}>
							<BlocksItem linkUrl="/trading" imageUrl={listBulletRectanglePortraitFill} title="特定商取引法に基づく表記" />
							<BlocksItem linkUrl="/terms" imageUrl={bookClosedFill} title="利用規約" />
							<BlocksItem linkUrl="/termsps" imageUrl={crownFill} title="プレミアムサービス利用規約" reverse={true} />
							<BlocksItem linkUrl="/contact/" imageUrl={questionmarkCircleFill} title="お問い合わせ" />
							<BlocksItem linkUrl="/help" imageUrl={textBubbleFill} title="ヘルプとガイド" />
							<BlocksItem linkUrl="/privacy" imageUrl={firewallFill} title="プライバシーポリシー" />
						</ul>
					</section>

					<div className="Home-download">
						<p>
							<span>いつでもどこでもお手軽に。</span>
							<br />ハンドクラフトのアプリを無料ダウンロード
						</p>
						<a target="_blank" rel="noreferrer" href={process.env.REACT_APP_APPSTORE}>
							<img src={appStore} alt="App Store アイコン" />
						</a>
					</div>

				</section>
			}
		/>
	);
};
