import './css/FaqCancel.css';
import { Content } from "../Content";
import { FaqHeader } from './FaqHeader';
import { FaqDetail } from './FaqDetail';
import { mediaQuery, useMediaQuery } from '../useMediaQuery'

export const FaqCancel = () => {
  const isSp = useMediaQuery(mediaQuery.sp);

  const details: React.CSSProperties = {
    flexDirection: isSp ? "column" : "row",
    gap: isSp ? 0 : "1.5rem"
  };

  return (
    <div>
      <Content
        children={
          <div>
            <FaqHeader title="注文のキャンセル" />
            <div className="Details" style={details}>
              <details>
                <summary><h3>販売者による注文のキャンセル</h3></summary>
                <FaqDetail summary="【販売者】注文キャンセルの手続き" url="faq_cancel_seller.html" />
                <FaqDetail summary="支払い状況が未払いのままでも注文のキャンセルはできますか？" url="faq_cancel_awaiting.html" />
              </details>
              <details>
                <summary><h3>購入者による注文のキャンセル</h3></summary>
                <FaqDetail summary="【購入者】注文キャンセルの手続き" url="faq_cancel_user.html" />
                <FaqDetail summary="間違えて購入したのですが注文のキャンセルはできますか？" url="faq_cancel_wrong.html" />
              </details>
            </div>
          </div>
        }
      />
    </div>
  );
};
