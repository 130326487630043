import './css/Message.css';
import { useEffect, useState } from 'react';
import { mediaQuery, useMediaQuery } from './useMediaQuery'

type Props = {
  message: string
  link?: string
  href?: string
  uuid: string
}

export const Message: React.FC<Props> = ({ message, link, href, uuid }) => {
  const isSp = useMediaQuery(mediaQuery.sp);
  const [isVisible, setIsVisible] = useState(false);

  const massageCss: React.CSSProperties = {
    display: isVisible ? "flex" : "none"
  };

  const massageContainerCss: React.CSSProperties = {
    width: "auto",
    maxWidth: isSp ? "40rem" : "60rem",
    fontSize: isSp ? ".75rem" : ".875rem"
  };

  useEffect(() => {
    if(uuid === "") return;
    setIsVisible(false);
    setIsVisible(true);
    const timer = setTimeout(() => {
      setIsVisible(false);
    }, 4000);

    return () => clearTimeout(timer);
  }, [uuid]);

  return (
    <div className="Message" style={massageCss}>
      <div className="Message-container" style={massageContainerCss}>
        <p className="Message-content">
          {message}
        </p>
        {link === undefined && href === undefined ? null : <a target="_blank" href={href}>{link}</a>}
      </div>
    </div>
  );
};
