import './css/FaqItem.css';
import { Content } from '../Content';
import { FetchHtml } from '../FetchHtml';
import { useLocation, useNavigate } from "react-router-dom";

export const FaqItem = () => {
    const navigate = useNavigate();
    const search = useLocation().search;
    const query = new URLSearchParams(search);
    const title = query.get('title') as string
    const url = query.get('url') as string

    return (
        <div>
            <Content
                children={
                    <div>
                        <div className="FaqItem-header">
                            <h4>{title}</h4>
                            <a className="Back" onClick={() => navigate(-1)}>戻る</a>
                        </div>
                        <FetchHtml url={`./help/${url}.html`} />
                    </div>
                }
                isNarrow={true}
            />
        </div >
    );
};
