import '../css/App.css';
import './css/ItemMake.css';
import { useState } from 'react';
import { mediaQuery, useMediaQuery } from '../useMediaQuery'
import { JObject, JArray } from '../types/interfaces';
import { createItemPathByName } from '../utils/helpers';
import { FullScreen } from '../FullScreen';

type Props = {
  item: JObject | undefined;
};

export const ItemMake: React.FC<Props> = ({ item }) => {

  const isSp = useMediaQuery(mediaQuery.sp);
  const itemId = item?.itemid as bigint;
  const itemName = item?.itemname as string;
  const makes = new JArray(item?.makes);
  const [isVisible, setIsVisible] = useState(false);
  const [fixedUrls, setFixedUrls] = useState<string[]>([]);

  const itemExplainCss: React.CSSProperties = {
    width: isSp ? "1.5rem" : "2rem",
    height: isSp ? "1.5rem" : "2rem"
  };

  const handleClick = (fixedUrls: string[]) => {
    setFixedUrls(fixedUrls);
    setIsVisible(true);
  };

  const handleClose = () => {
    setIsVisible(false);
  };

  return (
    makes.length === 0 ? null :
      <div className="ItemMake">
        <h2>作り方</h2>
        {makes?.map((p, i) => {
          const url = `https://server.handcraft-jp.com/${createItemPathByName(itemId, p.makeImageName)}`;
          const fixedMakes = Array(3).fill(p);
          const fixedUrls = fixedMakes.map(p => `https://server.handcraft-jp.com/${createItemPathByName(itemId, p.makeImageName)}`);
          return (
            <div key={i} className="ItemMake-item">
              <div className="ItemMake-item-number">{i + 1}</div>
              <div className="ItemMake-item-explain">{p.makeExplain}</div>
              {p.makeImageName === null ? null : <img className="ItemMake-item-image" src={url} onClick={() => handleClick(fixedUrls)}></img>}
            </div>
          )
        })}
        <FullScreen isVisible={isVisible} propsCurrentIndex={1} imageName={itemName} fixedUrls={fixedUrls} onClose={handleClose} isCounter={false} />
      </div>
  )
};

