import './css/App.css';
import { createContext, useState } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import { GlobalContextType } from './types/interfaces';
import { Header } from "./Header";
import { Home } from "./home/Home";
import { Trading } from "./trading/Trading";
import { Terms } from "./terms/Terms";
import { Termsps } from "./termsps/Termsps";
import { Contact } from "./contact/Contact";
import { Thanks } from "./contact/Thanks";
import { Privacy } from "./privacy/Privacy";
import { Faq } from "./help/Faq";
import { FaqPurchase } from "./help/FaqPurchase";
import { FaqSales } from "./help/FaqSales";
import { FaqCancel } from "./help/FaqCancel";
import { FaqItem } from "./help/FaqItem";
import { Item } from "./item/Item";
import { Footer } from "./Footer";

export const GlobalContext = createContext<GlobalContextType>({
  clickUuid: ""
});

function App() {
  const [clickUuid, setClickUuid] = useState("");

  const handleGlobalClick = () => {
    setClickUuid(uuidv4());
  };

  return (
    <GlobalContext.Provider value={{ clickUuid }}>
      <BrowserRouter>
        <div className="App" onClick={handleGlobalClick}>
          <Header />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/trading" element={<Trading />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/termsps" element={<Termsps />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/contact/thanks" element={<Thanks />} />
            <Route path="/help" element={<Faq />} />
            <Route path="/help/purchase" element={<FaqPurchase />} />
            <Route path="/help/sales" element={<FaqSales />} />
            <Route path="/help/cancel" element={<FaqCancel />} />
            <Route path="/help/item" element={<FaqItem />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/item" element={<Item />} />
          </Routes>
          <Footer />
        </div>
      </BrowserRouter>
    </GlobalContext.Provider>
  );
}

export default App;
