import React from 'react';
import './css/FaqDetail.css';
import { FetchHtml } from '../FetchHtml';

type Props = {
    summary: string;
    url: string;
}

export const FaqDetail: React.FC<Props> = ({summary, url}) => {
  return (
    <details>
      <summary><h4>{summary}</h4></summary>
      <FetchHtml url={`./help/${url}`} />
    </details>
  );
};
