import '../css/Common.css';
import { Content } from "../Content";
import { FetchHtml } from '../FetchHtml';

export const Trading = () => {

  return (
    <div>
      <Content
        children={
          <div>
            <h1>特定商取引法に基づく表記</h1>
            <FetchHtml url="./trading/trading.html" />
          </div>
        }
        isNarrow={true}
      />
    </div>
  );
};
