import './css/Common.css';
import './css/Content.css';
import { mediaQuery, useMediaQuery } from './useMediaQuery'

type Props = {
	children: JSX.Element
  padding?: boolean
	isNarrow?: boolean
}

export const Content: React.FC<Props> = ({ children, padding = true, isNarrow = false }) => {
	const isSp = useMediaQuery(mediaQuery.sp);

	const content: React.CSSProperties = {
		marginTop: isSp ? padding ? "10px" : 0 : "24px"
	};

	const contentMain: React.CSSProperties = {
		padding: isSp ? padding ? "16px" : 0 : "40px"
	};
	
	return (
		<div className="Content" style={content}>
			<div className={isNarrow ? "Common-wrap-narrow" : "Common-wrap"}>
				<div className="Content-main" style={contentMain}>
					{children}
				</div>
			</div>
		</div>
	);
};
