import "./css/FullScreen.css";
import React, { useState, useEffect, useRef } from 'react';
import { ImageCounter } from "./ImageCounter";
import { mediaQuery, useMediaQuery } from './useMediaQuery'
import circleXmark from "./svg/circle.xmark.svg";
import circleChevtronLeft from "./svg/circle.chevron.left.svg";
import circleChevtronRight from "./svg/circle.chevron.right.svg";

type Props = {
  isVisible: boolean;
  propsCurrentIndex: number;
  imageName: string;
  fixedUrls: string[];
  onClose: () => void;
  isCounter?: boolean;
};

export const FullScreen: React.FC<Props> = ({ isVisible, propsCurrentIndex, imageName: imageName, fixedUrls, onClose, isCounter=true }) => {

  const isSp = useMediaQuery(mediaQuery.sp);
  const [currentIndex, setCurrentIndex] = useState(propsCurrentIndex);
  const [isNext, setIsNext] = useState(true);
  const [isAnimating, setIsAnimating] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [startTime, setStartTime] = useState(0);
  const imageRef = useRef<HTMLImageElement[]>(null!);
  const containerRef = useRef<HTMLDivElement>(null!);

  imageRef.current = new Array(fixedUrls.length).fill(null);

  const fullScreenBackground: React.CSSProperties = {
    backgroundColor: "black",
    visibility: isVisible ? "visible" : "hidden",
    opacity: isVisible ? 0.6 : 0,
  };

  const fullScreenBase: React.CSSProperties = {
    visibility: isVisible ? "visible" : "hidden",
    opacity: isVisible ? 1 : 0,
  };
  
  const fullScreenChevron: React.CSSProperties = {
    display: fixedUrls.length > 3 ? "inline" : "none",
    opacity: isVisible ? 1 : 0,
  };

  const itemCounter: React.CSSProperties = {
    display: isCounter ? "flex" : "none",
    position: "absolute",
    top: ".5rem",
    left: `calc(50% - ${isSp ? "1.5rem" : "2rem"})`
  };

  // ボタンイベント
  const handlePrev = () => {
    if (isAnimating) return;
    setIsAnimating(true);
    setIsNext(false);
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? fixedUrls.length - 1 : prevIndex - 1));
  };

  const handleNext = () => {
    if (isAnimating) return;
    setIsAnimating(true);
    setIsNext(true);
    setCurrentIndex((prevIndex) => (prevIndex === fixedUrls.length - 1 ? 0 : prevIndex + 1));
  };

  // タッチイベント
  useEffect(() => {
    fixedUrls.forEach((p, i) => {
      imageRef.current[i]?.addEventListener("touchstart", onTouchStart, { passive: false });
      imageRef.current[i]?.addEventListener("touchmove", onTouchMove, { passive: false });
      imageRef.current[i]?.addEventListener("touchend", onTouchEnd, { passive: false });
    });
    return (() => {
      fixedUrls.forEach((p, i) => {
        imageRef.current[i]?.removeEventListener("touchstart", onTouchStart);
        imageRef.current[i]?.removeEventListener("touchmove", onTouchMove);
        imageRef.current[i]?.removeEventListener("touchend", onTouchEnd);
      });
    });
  }, [imageRef.current]);

  const onTouchStart = (e: TouchEvent) => {
    e.preventDefault();
    handleStart(e.touches[0].clientX);
  };

  const onTouchMove = (e: TouchEvent) => {
    e.preventDefault();
    handleMove(e.touches[0].clientX);
  };

  const onTouchEnd = (e: TouchEvent) => {
    e.preventDefault();
    handleEnd(e.changedTouches[0].clientX);
  };

  // マウスイベント
  const handleMouseDown = (e: React.MouseEvent) => handleStart(e.clientX);
  const handleMouseMove = (e: React.MouseEvent) => handleMove(e.clientX);
  const handleMouseUp = (e: React.MouseEvent) => handleEnd(e.clientX);
  const handleMouseLeave = (e: React.MouseEvent) => handleEnd(e.clientX);

  // 共通イベント
  const handleStart = (clientX: number) => {
    if (isAnimating) return;
    setIsDragging(true);
    setStartX(clientX);
    setStartTime(Date.now());
  };

  const handleMove = (clientX: number) => {
    if (isAnimating) return;
    if (!isDragging) return;
    const deltaX = clientX - startX;
    containerRef.current.style.transform = `translateX(calc(-${currentIndex * 100}% + ${deltaX}px))`;
    containerRef.current.style.transition = "none";
  };

  const handleEnd = (clientX: number) => {
    if (isAnimating) return;
    if (!isDragging) return;
    setIsDragging(false);
    // 位置判定
    const deltaX = clientX - startX;
    if (deltaX === 0) return;
    setIsAnimating(true);
    if (deltaX < -containerRef.current.clientWidth / 2) {
      setCurrentIndex(currentIndex + 1);
      setIsNext(true);
      return;
    }
    if (deltaX >= containerRef.current.clientWidth / 2) {
      setCurrentIndex(currentIndex - 1);
      setIsNext(false);
      return;
    }
    // 速度判定
    const endTime = Date.now();
    const duration = endTime - startTime;
    const velocity = Math.abs(deltaX) / duration;
    if (velocity > .3) {
      setCurrentIndex(deltaX < 0 ? currentIndex + 1 : currentIndex - 1);
      setIsNext(deltaX < 0);
      return;
    }
  };

  const handleImageRef = (e: HTMLImageElement | null, index: number) => {
    imageRef.current[index] = e!;
  };

  const handleTransitionEnd = () => {
    setIsAnimating(false);
    if (isNext && currentIndex === fixedUrls.length - 1) setCurrentIndex(1);
    if (!isNext && currentIndex === 0) setCurrentIndex(fixedUrls.length - 2);
  }

  const handleClick = () => {
    onClose();
  };

  useEffect(() => {
    setCurrentIndex(propsCurrentIndex);
  }, [propsCurrentIndex]);

  useEffect(() => {
    const animate = () => {
      containerRef.current.style.transform = `translateX(-${currentIndex * 100}%)`;
      containerRef.current.style.transition = `${!isAnimating && (currentIndex === 1 || currentIndex === fixedUrls.length - 2) ? "none" : "transform 0.3s ease-in-out"}`;
    };
    requestAnimationFrame(animate);
  }, [isVisible, currentIndex, isAnimating]);

  return (
    <div className="FullScreen">
      <div className="FullScreen-background" style={fullScreenBackground}></div>
      <div className="FullScreen-base" style={fullScreenBase}>
        <div className="FullScreen-image-slider">
          <div className="FullScreen-image-container" onTransitionEnd={handleTransitionEnd} ref={containerRef}>
            {fixedUrls.map((image, index) => (
              <img className="FullScreen-image" key={index} src={image} alt={`${imageName} ${index - 1}枚目`} onMouseDown={handleMouseDown} onMouseMove={handleMouseMove} onMouseUp={handleMouseUp} onMouseLeave={handleMouseLeave} ref={(e) => handleImageRef(e, index)} />
            ))}
          </div>
          <ImageCounter currentIndex={currentIndex} fixedUrls={fixedUrls} style={itemCounter} />
          <img className="FullScreen-prev" style={fullScreenChevron} src={circleChevtronLeft} onClick={handlePrev}></img>
          <img className="FullScreen-next" style={fullScreenChevron} src={circleChevtronRight} onClick={handleNext}></img>
        </div>
        <img className="FullScreen-close" src={circleXmark} onClick={handleClick} />
      </div>
    </div>
  );
};
