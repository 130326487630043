import '../css/Common.css';
import { Content } from "../Content";
import { FetchHtml } from '../FetchHtml';

export const Terms = () => {

  return (
    <div>
      <Content
        children={
          <div>
            <h1>利用規約</h1>
            <FetchHtml url="./terms/terms.html" />
          </div>
        }
        isNarrow={true}
      />
    </div>
  );
};
