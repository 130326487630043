import './css/Loading.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

type Props = {
	isLoading: boolean
}

export const Loading: React.FC<Props> = ({ isLoading }) => {

	const loading: React.CSSProperties = {
    visibility: isLoading ? "visible" : "hidden",
    opacity: isLoading ? .3 : 0
	};

  return (
    <div className="Loading" style={loading}>
      <FontAwesomeIcon icon={faSpinner} spinPulse size="3x" />
    </div>
  );
};
