import React, { useState, useEffect } from 'react';
import axios from 'axios';

type Props = {
  url: string;
}

export const FetchHtml: React.FC<Props> = ({url}) => {
  const [html, setHtml] = useState("");

  const convertUrl = (url: string) => {
    const protocol = window.location.hostname === "localhost" ? "http" : "https";
    return `${protocol}://${window.location.host}/${url}`;
  }

  useEffect(() => {
    const fetchUrl = async () => {
      try {
        const response = await axios.get(convertUrl(url));
        setHtml(response.data);
      } 
      catch (err) {
        console.error(err);
      }
    };
    fetchUrl();
  }, [url]);

  return (
    <div>
      <div dangerouslySetInnerHTML={{ __html: html }} />
    </div>
  );
};
