import './css/FaqPurchase.css';
import { Content } from "../Content";
import { FaqHeader } from './FaqHeader';
import { FaqDetail } from './FaqDetail';
import { mediaQuery, useMediaQuery } from '../useMediaQuery'

export const FaqPurchase = () => {
  const isSp = useMediaQuery(mediaQuery.sp);

  const details: React.CSSProperties = {
    flexDirection: isSp ? "column" : "row",
    gap: isSp ? 0 : "1.5rem"
  };

  return (
    <div>
      <Content
        children={
          <div>
            <FaqHeader title="購入" />
            <div className="Details" style={details}>
              <details>
                <summary><h3>作品の購入</h3></summary>
                <FaqDetail summary="利用できるお支払方法" url="faq_purchase_payment.html" />
                <FaqDetail summary="追加送料について" url="faq_purchase_addition.html" />
              </details>
              <details>
                <summary><h3>作品の購入後</h3></summary>
                <FaqDetail summary="注文後に支払い方法を変更できますか？" url="faq_purchase_change_payment.html" />
                <FaqDetail summary="注文後に配送先住所を変更できますか？" url="faq_purchase_change_address.html" />
              </details>
            </div>
          </div>
        }
      />
    </div >
  );
};
