import './css/FaqSales.css';
import { Content } from "../Content";
import { FaqHeader } from './FaqHeader';
import { FaqDetail } from './FaqDetail';
import { mediaQuery, useMediaQuery } from '../useMediaQuery'

export const FaqSales = () => {
  const isSp = useMediaQuery(mediaQuery.sp);

  const details: React.CSSProperties = {
    flexDirection: isSp ? "column" : "row",
    gap: isSp ? 0 : "1.5rem"
  };

  return (
    <div>
      <Content
        children={
          <div>
            <FaqHeader title="販売" />
            <div className="Details" style={details}>
              <details>
                <summary><h3>作品の販売</h3></summary>
                <FaqDetail summary="作品を販売するにあたり準備するものはありますか？" url="faq_sales_prepare.html" />
                <FaqDetail summary="海外から作品の販売はできますか？" url="faq_sales_abroad.html" />
              </details>
              <details>
                <summary><h3>作品の登録・編集</h3></summary>
                <FaqDetail summary="作品の出品数に制限はありますか？" url="faq_sales_limit.html" />
                <FaqDetail summary="販売しない作品も登録できますか？" url="faq_sales_display.html" />
              </details>
            </div>
          </div>
        }
      />
    </div >
  );
};
