import { deepCopy } from "../utils/helpers";

export interface GlobalContextType {
  clickUuid: string;
}

export interface JToken {
  [key: string]: any;
}

export class JObject implements JToken {
  [key: string]: any;

  constructor(token: JToken) {

    if (typeof token !== 'object' || token === null) {
      return;
    }

    for (const key in token) {
      if (token.hasOwnProperty(key)) {
        this[key] = deepCopy(token[key]);
      }
    }
  }

  public source = (): JObject => {
    const hits = new JArray(this.hits?.hits);
    const source = hits.firstOrDefault()?._source;
    return new JObject(source);
  }

  public sources = (): JArray => {
    const hits = new JArray(this.hits?.hits);
    return hits.select(p => p._source);
  }
  
  public total = (): number => {
    return this.hits?.total?.value as number;
  }
}

export class JArray extends Array<JToken> {

  constructor(items: JToken[]) {
    super(...(Array.isArray(items) && items?.filter(p => Object.keys(p).length !== 0) || []));
  }

  public where = (predicate: (p: JToken) => boolean): JArray => {
    return this.filter(predicate) as JArray;
  }

  public select = (transform: (value: JToken, index: number, array: JToken[]) => JToken): JArray => {
    return new JArray(this.map(transform));
  }

  public firstOrDefault = (): JObject | undefined => {
    return this.length > 0 ? new JObject(this[0]) : undefined;
  }

  public groupBy = <T>(getKey: (cur: JToken, idx: number, src: readonly JToken[]) => T): [T, JArray][] => {

    const map = this.reduce((map: Map<T, JArray>, cur, idx, src) => {
      const key = getKey(cur, idx, src);
      const list = map.get(key);
      if (list) list.push(cur);
      else map.set(key, new JArray([cur]));
      return map;
    }, new Map<T, JArray>());

    return Array.from(map.entries());
  };

  public ascend = (key: (a: JToken) => number): JArray => {
    return this.sort((p, q) => key(p) - key(q));
  }

  public ascends = (key1: (a: JToken) => number, key2: (b: JToken) => number): JArray => {
    return this.sort((p, q) => {
      if (key1(p) !== key1(q)) return key1(p) - key1(q);
      return key2(p) - key2(q);
    });
  }

  public descend = (key: (a: JToken) => number): JArray => {
    return this.sort((p, q) => key(q) - key(p));
  }

  public descends = (key1: (a: JToken) => number, key2: (b: JToken) => number): JArray => {
    return this.sort((p, q) => {
      if (key1(p) !== key1(q)) return key1(q) - key1(p);
      return key2(q) - key2(p);
    });
  }
}

export class ErrorEx extends Error {

  errors: string[];

  constructor(errors: string[]) {
    super();
    this.name = "ErrorEx";
    this.errors = errors;
  }
}
