import '../css/App.css';
import './css/ItemMaterial.css';
import { mediaQuery, useMediaQuery } from '../useMediaQuery'
import { JObject, JArray } from '../types/interfaces';

type Props = {
  item: JObject | undefined;
};

export const ItemMaterial: React.FC<Props> = ({ item }) => {

  const isSp = useMediaQuery(mediaQuery.sp);
  const materials = new JArray(item?.materials);

  return (
    materials.length === 0 ? null :
      <div className="ItemMaterial">
        <h2>材料</h2>
        {materials?.map((p, i) =>
          <div key={i} className="ItemMaterial-item" style={{ backgroundColor: i % 2 === 0 ? "#f4f3f2" : "transparent" }}>
            <h2>{p.materialName}</h2>
            <div>{p.materialAmount}</div>
          </div>
        )}
      </div>
  )
};

