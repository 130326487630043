import '../css/Common.css';
import { Content } from "../Content";
import { FetchHtml } from '../FetchHtml';

export const Privacy = () => {
  return (
    <div>
      <Content
        children={
          <div>
            <h1>プライバシーポリシー</h1>
            <FetchHtml url="./privacy/privacy.html" />
          </div>
        }
        isNarrow={true}
      />
    </div>
  );
};
