import './css/FooterMenu.css';
import { mediaQuery, useMediaQuery } from './useMediaQuery'

export const FooterMenu = () => {
	const isPc = useMediaQuery(mediaQuery.pc);
	const isSp = useMediaQuery(mediaQuery.sp);

	const footerMenu: React.CSSProperties = {
		display: isPc ? "none" : "flex"
	}

	const footerMenuNavi: React.CSSProperties = {
		maxWidth: isSp ? "53%" : "400px",
		fontSize: isSp ? "16px" : "19px"
	}

	const footerMenuFont: React.CSSProperties = {
        fontSize: isSp ? "14px" : "16px"
	}

	const returnTop = () => {
		window.scrollTo({
			top: 0,
			behavior: "smooth",
		});
	};

	return (
		<ul className="Footer-menu" style={footerMenu}>
			<li>
				<input type="checkbox" id="footer-menu" />
				<label htmlFor="footer-menu">
					<i className="fa fa-bars" style={footerMenuFont}></i>
					<span>メニュー</span>
				</label>
				<label className="Footer-menu-base" htmlFor="footer-menu"></label>
				<div className="Footer-menu-navi" style={footerMenuNavi}>
					<label htmlFor="footer-menu">
						<i className="fa fa-close fa-2x"></i>
					</label>
					<ul>
						<li>
							<a href="/trading">特定商取引法に基づく表記</a>
						</li>
						<li>
							<a href="/terms">利用規約</a>
						</li>
						<li>
							<a href="/termsps">プレミアムサービス利用規約</a>
						</li>
						<li>
							<a href="/contact">お問い合わせ</a>
						</li>
						<li>
							<a href="/help">ヘルプとガイド</a>
						</li>
						<li>
							<a href="/privacy">プライバシーポリシー</a>
						</li>
					</ul>
				</div>
			</li>
			<li>
				<a href="/">
					<i className="fa fa-home" style={footerMenuFont}></i>
					<span>ホーム</span>
				</a>
			</li>
			<li>
				<input type="checkbox" id="footer-search" />
				<label htmlFor="footer-search">
					<i className="fa fa-search" style={footerMenuFont}></i>
					<span>検索</span>
				</label>
				<label className="Footer-menu-base" htmlFor="footer-search"></label>
				<div className="Footer-menu-search">
					<form method="get" action="/main/">
						<input placeholder="サイト内を検索"></input>
						<button type="submit">
							<i className="fa fa-search"></i>
						</button>
					</form>
				</div>
			</li>
			<li>
				<a onClick={() => returnTop()} className="Footer-menu-top">
					<i className="fa fa-arrow-up" style={footerMenuFont}></i>
					<span>トップ</span>
				</a>
			</li>
		</ul>
	);
};
