import { Content } from "../Content";

export const Thanks = () => {

  return (
    <div>
      <Content
        children={
          <div>
            <p>
              お問い合わせありがとうございます。<br />
              2営業日以内に担当者から連絡いたします。
            </p>
            <p>
              お問い合わせの際の注意事項
            </p>
            <ul>
              <li>原則2営業日以内にご連絡を差し上げますが、お問い合わせが混み合っている、または、調査等が必要なお問い合わせは、返信までにお時間をいただく場合がございます。</li>
              <li>迷惑メール設定等により、「ハンドクラフト」からの返信を受信できない場合がございます。「@handcraft-jp.com」の受信設定の許可をお願いします。</li>
            </ul>
          </div>
        }
        isNarrow={true}
      />
    </div>
  );
};
